.rsvps-container {
  flex-grow: 1;

  .message-attendees {
    background-color: $light-gray;
    border-radius: $border-radius;
    margin-top: 40px;

    .message-form {
      margin-top: 20px;

      .btn {
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        width: 200px;
      }
    }
  }
}

.rsvp-detail-section {
  margin-top: 48px;

  &:first-of-type {
    margin-top: 0;
  }

  @include media-breakpoint-between(xs, md) {
    th {
      display: none;
    }

    tr {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }

    td {
      padding: 0 !important;
    }

    .rsvp-name {
      font-weight: 900;
    }
  }

  h4 {
    margin-bottom: 21px;
  }

  table {
    thead {
      tr {
        th {
          border-top: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 0;
          padding: 15px 0;
        }
      }
    }
  }
}

.add-rsvp-form {
  width: 100%;

  .rsvp-fields {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    > div {
      flex: 100% 0 0;

      @include media-breakpoint-up(md) {
        flex: auto 1 0;
      }

      &:last-child {
        @include media-breakpoint-up(md) {
          flex: 15% 0 0;
        }
      }
    }
  }
}

.rsvp-attendees-list-group {
  .rsvp-attendee-formset-form {
    &:not(.hidden) {
      border-top: 1px solid $border-color;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;

      ~ :not(.hidden) {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
