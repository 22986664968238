.deceased-photo-modal {
  overflow-y: scroll;
  overscroll-behavior: contain;

  .modal-dialog {
    min-width: 90vw;

    @include media-breakpoint-up(lg) {
      min-width: 0;
    }
  }

  @include media-breakpoint-up(md) {
    overflow-y: visible;
  }

  input {
    &[type="file"] {
      display: none;
    }
  }

  .preview-frame {
    &.uploading {
      max-height: 200px;
      overflow: hidden;
    }
  }

  .photo-tile-container {
    margin-bottom: 40px;
    max-height: calc(95vh - 300px);
    -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    padding: 0;

    .photo-tile {
      border: 1px solid $white;
      cursor: pointer;
      flex: 0 0 calc(33.33%);
      position: relative;

      @include media-breakpoint-up(md) {
        flex: 0 0 calc(20%);
      }

      &::before {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 75%;
      }

      .photo {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:nth-child(1) {
        border-top-width: 0;
      }

      &:nth-child(2) {
        border-top-width: 0;
      }

      &:nth-child(3) {
        border-top-width: 0;
      }

      &:nth-child(4) {
        border-top-width: 0;
      }

      &:nth-child(5) {
        border-top-width: 0;
      }

      &:nth-child(5n + 1) {
        border-left-width: 0;
      }

      &:nth-child(5n + 2) {
        border-right-width: 0;
      }

      .remove-tile-button {
        background-color: $white-overlay;
        border-radius: 50%;
        color: $dark-gray-text;
        font-size: 28px;
        height: 28px;
        line-height: 93%;
        position: absolute;
        right: 2px;
        text-align: center;
        top: 2px;
        width: 28px;

        @include media-breakpoint-up(md) {
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .preview-photos {
    &.onboarding {
      display: flex;
      flex-direction: column;
      grid-gap: 4px;

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        overflow: hidden;
      }

      .preview-photo {
        border-radius: $border-radius-sm;
        max-height: 100%;
        min-height: 0;

        @include media-breakpoint-up(md) {
          height: 100%;
        }

        > img {
          border-radius: $border-radius-sm;
        }
      }
    }

    &:not(.onboarding) {
      display: flex;
      flex-direction: column;
    }

    .preview-photo {
      position: relative;

      @include media-breakpoint-only(xs) {
        border-radius: 0 !important;

        > img {
          border-radius: 0 !important;
        }
      }

      &:first-child {
        .primary-banner {
          bottom: 0;
          display: block;
          left: 0;
          margin: 8px;
          position: absolute;
          z-index: $zindex-default;
        }
      }

      &.placeholder {
        align-items: center;
        border: 2px dashed $gray-600;
        color: $gray-600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 0;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          padding: 0;
        }

        img {
          height: auto;
          width: 30%;
        }
      }

      .primary-banner {
        display: none;
      }

      .photo-controls {
        display: flex;
        margin: 8px;
        position: absolute;
        top: 0;
        z-index: $zindex-default;

        &.crop {
          right: 0;
        }

        &.move {
          left: 0;
        }

        a {
          background-color: $white;
          border-radius: 14px;
          color: $black;
          font-size: 28px;
          font-weight: $font-weight-semibold;
          height: 28px;
          line-height: 24px;
          margin: 0 2px;
          text-align: center;
          width: 28px;

          @include media-breakpoint-up(md) {
            border-radius: 10px;
            font-size: 20px;
            height: 20px;
            line-height: 18px;
            width: 20px;
          }

          &.preview-remove,
          &.preview-change,
          &.preview-crop {
            font-size: 12px;
            padding: 2px 8px;
            width: auto;

            @include media-breakpoint-up(md) {
              font-size: 14px;
              padding: 1px 6px;
            }
          }
        }
      }

      .photo {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .cancel-photo-selection {
    margin-left: 8px;
  }

  .save-photos-btn {
    margin: 10px 0 0;
  }
}

.upload-overlay {
  background-color: $white;
  display: flex;
  height: 100%;
  left: 0;
  opacity: 0.98;
  padding: 60px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $zindex-default;

  .el-progress {
    margin: 20px;
    width: calc(100% - 40px);
  }
}

.incomplete-warning {
  max-width: 500px;
}

.cropper-dvh100 {
  max-height: 100vh;
  max-height: 100dvh;
}
