.invite-manager-survivor-form {
  flex-basis: 100%;
  flex-shrink: 0;

  @include media-breakpoint-between(xs, md) {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(md) {
    flex-basis: 100%;
  }

  @include media-breakpoint-up(xl) {
    flex-basis: calc(50% - 20px);
    flex-shrink: 1;
  }
}

.payment-details-container {
  .body-card {
    > div {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .payment-details-points {
    .payment-detail {
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      div {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .form-submit-row {
    .btn-form-submit {
      margin: 0 0 20px;

      @include media-breakpoint-between(xs, md) {
        padding-top: 0;
      }

      @include media-breakpoint-up(md) {
        margin: 0 40px 0 0;
      }
    }
  }
}

.memorial-onboarding-add-photo-container {
  .start-select-photos {
    margin: 0;
    width: 100%;
  }
}

.memorial-onboarding-form-container {
  .body-card {
    .callout {
      background-color: $blue-gray-background;
      border-radius: $border-radius-lg;
      margin-bottom: 20px;
      padding: 20px;
    }
  }

  .profile-upload-container {
    @include media-breakpoint-between(xs, lg) {
      margin-bottom: 0;
    }
  }

  .cause-tip,
  .photo-tip {
    width: auto;

    .tip-body {
      padding: 0;
    }

    .nav-arrow-list {
      padding: 20px;

      .nav-arrow {
        li {
          padding: 0 0 10px 10px;
        }
      }
    }
  }

  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      padding: 0;
    }

    .btn-form-submit {
      margin: 0 40px 0 0;

      @include media-breakpoint-between(xs, md) {
        margin: 0 0 20px;
        padding-top: 0;
      }
    }
  }
}

.memorial-onboarding-events-container {
  .events-state-non-public,
  .events-state-pending,
  .events-state-no-info {
    @include media-breakpoint-between(xs, md) {
      margin-bottom: 40px;
    }
  }

  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;

    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 40px;
    }

    .btn-form-submit {
      margin: 0 40px 0 0;

      @include media-breakpoint-between(xs, lg) {
        margin: 0 0 20px;
        padding-top: 0;
      }
    }
  }
}

.memorial-onboarding-configure-fundraiser-container {
  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 40px;
      padding: 0;
    }

    .btn-form-submit {
      margin: 0 40px 0 0;

      @include media-breakpoint-between(xs, md) {
        margin: 0 0 20px;
        padding-top: 0;
      }
    }
  }
}

.memorial-onboarding-add-family-container {
  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 40px;
      padding: 0;
    }

    .btn-form-submit {
      margin: 0 40px 0 0;

      @include media-breakpoint-between(xs, md) {
        margin: 0 0 20px;
        padding-top: 0;
      }
    }
  }
}

.memorial-onboarding-help-out-container {
  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 40px;
      padding: 0;
    }

    .btn-form-submit {
      margin: 0 40px 0 0;

      @include media-breakpoint-between(xs, md) {
        margin: 0 0 20px;
        padding-top: 0;
      }
    }
  }
}

.memorial-onboarding-share-container {
  @include media-breakpoint-between(xs, md) {
    margin-top: 0;
    padding: 0 20px;
  }

  .form-submit-row {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 40px;
      padding: 0;
    }

    a {
      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }
    }

    .link-secondary {
      margin: 20px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 20px;
      }
    }
  }

  .share-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-between(xs, md) {
      margin: 0;
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      align-items: flex-end;
      flex-direction: row;
    }

    .share-container-photo {
      border-radius: $border-radius-lg;
      flex-shrink: 0;
      margin-right: 30px;
      max-width: 36%;

      @include media-breakpoint-up(xl) {
        max-width: 35%;
      }
    }

    .share-container-grow {
      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }

      .share-prompt {
        margin: 0 0 10px;

        @include media-breakpoint-up(lg) {
          margin: 10px 0;
        }
      }

      .example-share-content {
        border: 1px solid $input-border-color;
        border-radius: $border-radius-lg;
        font-size: 0.9375rem;
        position: relative;

        @include media-breakpoint-up(xl) {
          font-size: 1.125rem;
        }

        p {
          margin-bottom: 30px;
          padding: 10px;
        }

        .btn-xs {
          border-radius: $border-radius-lg 0;
          bottom: 0;
          font-size: 0.85rem;
          min-width: 0;
          padding: 8px 24px;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .btn-facebook-share {
    img {
      bottom: 2px;
      margin-right: 4px;
      position: relative;
    }
  }
}

.ai-obituary-form-container {
  &.htmx-request {
    max-height: 80vh;
    max-height: 80dvh;

    @include media-breakpoint-up(md) {
      max-height: 200px;
      overflow: hidden;
    }
  }
}

.ai-compose-question {
  margin-bottom: 1rem;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    background-color: var(#{$prefix}light);
    border-radius: $border-radius;
  }
}
