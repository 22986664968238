@use "sass:color";
@use "sass:map";

.has-full-height-sidebar {
  overflow: auto;

  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }
}

.private-container {
  @include media-breakpoint-only(md) {
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    height: calc(100vh - 57px);
  }

  .private-sidebar {
    background-color: $very-light-gray;
    border-bottom: 1px solid $medium-gray;
    box-shadow: 0 10px 10px -4px $black-with-10-percent-opacity;
    flex: 0 0 auto;
    max-width: 100%;
    top: -60px;
    width: 100%;
    z-index: $zindex-fixed;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
      border-right: 1px solid $medium-gray;
      box-shadow: 10px 0 10px -8px $black-with-10-percent-opacity;
      flex: 0 0 300px;
      height: 100vh;
      left: 0;
      max-width: 300px;
      position: sticky;
      top: 0;
      transition:
        left 0.25s ease-in-out,
        margin-left 0.25s ease-in-out;
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      box-shadow: none;
      height: calc(100vh - 60px);

      // noinspection CssInvalidPropertyValue
      height: calc(100dvh - 60px);
      position: static;
      transition: none;
      z-index: auto;
    }

    @include media-breakpoint-up(xl) {
      height: auto;
    }

    &.in-dashboard {
      @include media-breakpoint-between(xs, md) {
        top: calc(-173px - 66.67vw);
      }
    }

    &.hidden {
      left: 0;
      margin-left: 0;

      @include media-breakpoint-only(md) {
        left: -300px; // Set both `left` and `margin-left` to move the element
        margin-left: -300px; // and move the threshold at which it becomes sticky.\
      }

      .sidebar-toggle {
        background-color: var(--secondary-brand-color);
        color: $white;
      }

      .sidebar-toggle-hide {
        display: none;
      }

      .sidebar-toggle-show {
        display: block;
      }
    }

    .sidebar-toggle-hide {
      color: var(--secondary-brand-color);
      display: block;
    }

    .sidebar-toggle-show {
      display: none;
    }

    .sidebar-toggle {
      background-color: $very-light-gray;
      border-bottom-right-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
      box-shadow: 10px 0 10px -8px $black-with-10-percent-opacity;
      color: var(--secondary-brand-color);
      left: 300px;
      position: absolute;
      top: 160px;

      > div {
        cursor: pointer;
        height: 200px;
        width: 36px;

        > div {
          height: 36px;
          transform: rotate(-90deg) translate(-200px, 0);
          transform-origin: 0 0;
          width: 200px;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .btn-sidebar-mobile-public {
      background-color: var(--primary-brand-color-light);
      color: var(--primary-brand-color);
      height: 60px;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 53px;
      }

      img {
        &:first-child {
          margin-right: 6px;
        }

        &:last-child {
          margin-left: 6px;
        }
      }
    }

    .sidebar-header {
      @include media-breakpoint-up(md) {
        margin: 10px;
      }

      .deceased-photo {
        flex: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          flex: 0 0 20%;
          margin-right: 10px;
          width: auto;
        }

        img {
          height: 100%;
          width: 100%;

          @include media-breakpoint-up(md) {
            border-radius: $border-radius-sm;
          }
        }
      }

      .deceased-name {
        flex: 1 1 auto;
        font-weight: $font-weight-semibold;
        height: 80px;
        width: 100%;

        @include media-breakpoint-up(md) {
          height: auto;
        }

        .name {
          word-break: break-word;
          word-wrap: break-word;

          @include media-breakpoint-between(xs, md) {
            font-size: 1.125rem;
          }
        }
      }
    }

    .sidebar-sharing {
      margin: 0 10px 10px;

      .sharing-buttons {
        margin-top: 6px;

        .light-gray-label {
          font-size: 0.55rem;
          margin-top: 3px;
          text-align: center;
        }

        .sharing-btn {
          color: $white;
          flex: 1 1 calc(33.33% - 2.6667px);
          font-size: 0.5rem;
          height: 36px;
          min-width: auto;
          padding: 5px 10px;
          text-transform: uppercase;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          img {
            margin-top: -1px;
          }
        }
      }
    }

    .sidebar-alerts {
      border-top: 1px solid $border-color;
      max-height: 300px;
      overflow-y: scroll;
      overscroll-behavior: contain;
      padding: 10px;

      &.collapsed {
        max-height: 40px;
        overflow-y: hidden;
      }

      .light-gray-label {
        .rounded-pill {
          padding-top: 1px;
          position: relative;
          top: -1px;
        }
      }

      .sidebar-alerts-container {
        margin-top: 2px;
        max-height: 200px;

        @include media-breakpoint-up(xl) {
          max-height: 400px;
        }

        form {
          width: 100%;
        }

        .private-alert {
          color: $private-alert-red;
          margin: 2px 0;
          width: 100%;

          .private-alert-contents {
            > :nth-child(2) {
              flex-grow: 1;
              margin: 0 10px;

              .text-note-small {
                font-weight: $font-weight-normal;
              }
            }

            img {
              height: 18px;
              margin-top: 4px;
              width: 18px;
            }
          }
        }
      }
    }

    .sidebar-mobile-actions {
      margin: 10px;
      position: relative;

      > div,
      > a {
        flex-basis: calc(50% - 4px);
        margin: 0; // TODO: Refactor small-button
        min-width: auto;

        img {
          margin-top: -2px;
        }
      }

      .dropdown-toggle {
        &.show,
        &:active,
        &:hover,
        &:focus {
          .text-bg-private-alert-red {
            background-color: $white !important;
            color: $private-alert-red !important;
          }
        }
      }
    }

    .sidebar-nav {
      border-top: 1px solid $border-color;
      flex: 1;
      overflow-y: scroll;

      .nav-link {
        --#{$prefix}nav-link-color: var(--primary-brand-color);
        --#{$prefix}nav-link-hover-color: var(--primary-brand-color);

        background-color: $white;
        border-bottom: 1px solid $medium-gray;
        padding: 12px 16px;
        word-break: break-word;
        word-wrap: break-word;

        .rounded-pill {
          font-size: 0.75rem;
          margin-left: 6px;
          position: relative;
          top: -2px;
        }

        &.active {
          background-color: var(--primary-brand-color);
          color: $white;
        }

        &:hover {
          background-color: var(--primary-brand-color-light);

          &.active {
            background-color: var(--primary-brand-color);
          }
        }

        &:last-child {
          border-bottom: 1px solid $medium-gray;
        }
      }
    }

    .sidebar-nav-mobile {
      margin: 0 10px 10px;
      position: relative;
    }

    .dropdown {
      .btn {
        width: 100%;
      }

      &.show {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        .btn {
          border-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.private-sidebar-mobile-dropdown {
        .dropdown-toggle {
          margin: 0; // TODO: Refactor small-button
        }

        .dropdown-menu {
          border: 1px solid $light-gray-text;
          max-height: 85vh;
          overflow-y: scroll;
          overscroll-behavior: contain;

          .dropdown-item {
            color: var(--primary-brand-color);

            .badge {
              font-size: 0.8375rem;
              margin-left: 4px;
            }

            &.dropdown-item-alert {
              color: $private-alert-red;

              &.active,
              &:active {
                background-color: $private-alert-red;
              }

              &.view-all {
                color: $light-gray-text;

                .badge {
                  margin-top: 2px;
                }
              }
            }

            &.active,
            &:active {
              background-color: var(--primary-brand-color);
              color: $white;
            }

            img {
              position: relative;
              top: -2px;
            }
          }
        }
      }

      .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: -1px;
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: calc(100vw - 20px);
        }

        &.show {
          box-shadow: $private-alerts-card-shadow;
        }

        .dropdown-item {
          font-size: 1.25rem;
          padding: 6px 14px;
          white-space: normal;

          &.dropdown-item-alert {
            font-size: 0.9375rem;
          }

          .d-flex {
            > :nth-child(2) {
              flex-grow: 1;
              margin: 0 10px;
            }

            img {
              height: 18px;
              margin-top: 6px;
              width: 18px;
            }
          }

          &:hover {
            font-weight: $font-weight-normal;
          }

          &.disabled {
            color: $light-gray-text;
            font-size: 0.9375rem;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .private-content-container {
    flex: 1 1 auto;
    overflow-y: auto;
    scroll-behavior: smooth;

    @include media-breakpoint-only(md) {
      margin-top: -100vh;
    }

    .messages-container {
      margin: 0;
      max-width: 100% !important;

      .alert {
        --#{$prefix}alert-border-radius: 0;

        margin: 0;

        @include media-breakpoint-only(md) {
          --#{$prefix}alert-border-radius: #{$border-radius};

          margin: 20px 20px 0;
          width: calc(100% - 40px);
        }
      }
    }

    .private-content {
      margin: 0 auto;
      max-width: 1095px;

      @include media-breakpoint-up(md) {
        padding: 0 20px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 40px;
      }

      .edit-memorial-container {
        padding: 0;

        .memorial-deceased-form-container {
          .memorial-deceased-form-photos {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: calc(50% - 40px);
            }

            @include media-breakpoint-up(lg) {
              width: 100%;
            }

            @include media-breakpoint-up(xl) {
              width: calc(50% - 40px);
            }
          }

          .deceased-metadata {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: 50%;
            }

            @include media-breakpoint-up(lg) {
              width: 100%;
            }

            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
        }
      }

      .onboarding-body-card {
        margin: 0;
        padding: 20px 0;

        @include media-breakpoint-up(md) {
          margin: 40px 0;
        }

        .onboarding-body-card-content {
          padding: 20px;

          @include media-breakpoint-up(md) {
            padding: 40px;
          }

          .subheader {
            margin-bottom: 40px;
            text-align: center;

            @include media-breakpoint-up(md) {
              font-size: 1.125rem;
              text-align: left;
            }

            @include media-breakpoint-up(xl) {
              font-size: 1.3125rem;
            }
          }

          .onboarding-card-illustrations {
            margin-top: 10px;
            text-align: center;

            @include media-breakpoint-up(md) {
              margin-top: 20px;
            }

            @include media-breakpoint-up(xl) {
              text-align: left;
            }

            .onboarding-card-illustrations-subcontainer {
              width: 100%;

              &:first-of-type {
                @include media-breakpoint-between(xs, md) {
                  margin: 20px 0;
                }
              }

              .illustration-group {
                margin-top: 10px;
                width: 130px;

                @include media-breakpoint-up(md) {
                  margin-top: 40px;
                }

                @include media-breakpoint-up(xl) {
                  margin-top: 0;
                  width: auto;
                }

                &:last-of-type {
                  margin-right: 0;
                }

                .icon-wrapper {
                  height: 57px;
                }
              }
            }

            .illustration-caption {
              text-align: center;
              vertical-align: middle;

              @include media-breakpoint-up(md) {
                margin: 10px 0 0;
              }

              @include media-breakpoint-up(xl) {
                margin: 0 0 0 10px;
                text-align: left;
              }
            }
          }

          .cta {
            margin-bottom: 20px !important;
            text-align: left;
          }

          .cta-qr-code {
            margin-bottom: 20px;
          }

          .share-cta-container {
            > div {
              flex: 0 0 calc(33.33% - 6.6667px);
              flex-direction: column;
              margin: 0 0 20px !important;
              min-width: 0;
              width: 100%;

              @include media-breakpoint-up(md) {
                margin: 0;
                width: auto;
              }

              @include media-breakpoint-up(xl) {
                flex-basis: calc(33.33% - 13.333px);
              }

              .btn {
                @include media-breakpoint-between(xs, xl) {
                  font-size: 0.9735rem;
                }
              }
            }

            .btn {
              min-width: auto;
              width: 100%;

              @include media-breakpoint-between(xs, md) {
                max-width: 100%;
              }
            }

            .btn-facebook-share {
              img {
                width: 57px;
              }
            }
          }

          .email-btn-container {
            a {
              color: $white;
            }
          }
        }
      }

      .deceased-info-and-nav {
        .deceased-photo {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 350px;
          }

          @include media-breakpoint-up(lg) {
            width: 260px;
          }

          @include media-breakpoint-up(xl) {
            width: auto;
          }

          .edit-photo {
            margin-top: -72px;
          }
        }
      }

      .creator-recommendations {
        &.body-card-no-padding {
          @include media-breakpoint-between(xs, md) {
            border-radius: 0;
          }

          > div {
            @include media-breakpoint-between(xs, md) {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.creator-recommendations {
  margin: 20px 0;
  max-width: 1095px;

  @include media-breakpoint-between(xs, md) {
    @include el-light-border-top;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 auto;
  }

  h2 {
    margin-bottom: 10px;
    padding: 20px 20px 0;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .subheader {
    padding: 0 20px 20px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .link-secondary {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }

  .creator-recommendations-wrapper {
    margin-top: 20px;

    .creator-recommendations-row {
      width: 100%;

      .creator-recommendation {
        margin: 20px;
      }
    }
  }
}

.premium-callout {
  background-color: $blue-gray-background;
  border-radius: $border-radius-lg;
  font-size: 1.125rem;
  padding: 16px;
}

.private-all-alerts {
  &.expand-alerts {
    .list-group {
      max-height: none;
    }
  }

  &.full-screen {
    height: 100vh;
    left: 0;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding: 0 0 100px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $zindex-modal;

    > div {
      &:first-of-type {
        padding: 0 20px;
      }
    }

    .close-alerts {
      display: block;
      margin: 20px 0 20px 20px;
    }

    .list-group {
      border-radius: 0;
      max-height: none;

      .list-group-item {
        border-left: 0;
        border-radius: 0;
        border-right: 0;
      }
    }
  }

  h2 {
    line-height: 1;
    word-break: break-word;
    word-wrap: break-word;
  }

  .close-alerts {
    display: none;
  }

  .list-group {
    background-color: $nearly-white-gray;
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    max-height: 245px;
    overflow-y: scroll;
    overscroll-behavior: contain;

    .list-group-item {
      background-color: transparent;
    }
  }

  .private-alert {
    color: $lighter-gray-text;

    .alert-title {
      img {
        height: 16px;
        margin-left: 2px;
        position: relative;
        top: -3px;
        width: 16px;
      }
    }

    > :first-child {
      flex-grow: 1;

      .text-note-small {
        font-weight: $font-weight-normal;
      }
    }

    .dismiss-alert-button {
      color: $lighter-gray-text;
      font-size: 0.75rem;
      text-transform: uppercase;

      .times {
        font-size: 1rem;
        position: relative;
        top: 1px;
      }
    }

    img {
      flex: 0 0 32px;
      height: 32px;
      margin: 4px 4px 0 0;
      width: 32px;
    }
  }
}

.republish-alert {
  color: $private-alert-red;
  padding: 0;
  text-align: left;

  &:hover {
    color: $private-alert-red;
  }
}

.private-settings-links {
  @include media-breakpoint-between(xs, md) {
    margin: 0 20px;

    .link-secondary {
      font-size: 1.125rem;
    }
  }

  .unpublish-link-container {
    margin-top: 40px;
  }

  .user-settings-link {
    margin-top: 40px;
  }
}

.create-memorials-container {
  margin-top: 20px;

  .create-memorials-card {
    background-color: $white;
    border-top: 1px solid $border-color;
    flex-basis: 100%;
    padding: 20px;
    transition: box-shadow 0.2s ease-in-out;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
      border-top: 0;
      box-shadow: $card-shadow;
      flex-basis: calc(33.33% - 13.333px);
      margin: 20px 20px 20px 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: none;

      @include media-breakpoint-up(md) {
        box-shadow: $card-shadow-hover;
      }
    }

    h4 {
      color: $dark-gray-text;
    }
  }
}

.change-publish-state-form {
  margin-top: 40px;

  .btn {
    padding: 0;
  }
}

.custom-select-with-caret {
  &.small-select {
    &.currency-select {
      border: 0;

      &::before {
        background: url("../svg/caret-down.svg") no-repeat right 8px center / 14px 8px;
      }

      &:hover {
        background-color: $white;
      }

      select {
        cursor: pointer;
        padding: 4px 26px 4px 0;
        width: auto;
      }
    }
  }
}

.contributions-section-container {
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }

  h3 {
    margin-top: 20px;
  }

  .no-contributions-message {
    margin-bottom: 10px;
  }

  .contributions-table-section {
    margin-bottom: 10px;
    max-height: 400px;

    tr {
      td {
        text-align: center;

        &:first-of-type {
          text-align: left;
        }
      }

      th {
        text-align: center;

        &:first-of-type {
          text-align: left;
        }
      }
    }

    .bold {
      font-weight: $font-weight-semibold;
    }

    .unknown-display-name {
      font-style: italic;
    }

    .sent-thank-you-container {
      padding-left: 8px;
    }

    .send-message {
      img {
        margin-top: 4px;
      }
    }
  }

  .csv-link-section {
    text-align: end;
  }

  .link-section {
    a {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .helpful-link-wrapper {
      margin-bottom: 10px;
    }
  }
}

.deceased-photos-layout-editor {
  .layout-selector {
    flex: 0 0 calc(33% - 10px);
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: var(--primary-brand-color-light) !important;
      border-color: var(--primary-brand-color) !important;

      &:not(.selected) {
        .preview-photo {
          img {
            filter: grayscale(0) opacity(100%);
          }
        }
      }

      .preview-photo {
        .photo-counter {
          opacity: 1 !important;
        }
      }
    }

    &:not(.selected) {
      .preview-photo {
        img {
          filter: grayscale(40%) opacity(40%);

          @include media-breakpoint-up(md) {
            filter: grayscale(80%) opacity(30%);
          }
        }
      }
    }
  }

  .layout-preview-container {
    overflow: hidden;
  }

  .layout-preview {
    --memorial-collage-radius: #{$border-radius-sm};
    --memorial-collage-grid-gap: 1px;
  }

  .preview-photo {
    .photo-counter {
      opacity: 0 !important;
      text-shadow: 0 0 3px rgba(0 0 0 / 0.5);
      transition: all 300ms ease-in-out;
    }

    > img {
      display: block;
      height: 100%;
      max-width: 100%;
      transition: all 300ms ease-in-out;
      width: 100%;
    }
  }
}

.edit-memorial-container {
  .alert {
    &.photos-uploaded-banner {
      font-size: 0.9375rem;
      margin-top: 20px;
      text-align: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 425px;
      }
    }
  }
}

.qr-code-container {
  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  img {
    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }
  }
}

.download-qr-codes-container {
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    margin: 40px 0 0;
    padding: 0;
  }

  a {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

.body-card {
  &.private-pre-deceased-checklist {
    box-shadow: 0 0 5px 0 $orange;
  }
}

.partner-obituary-print-order-promo-card {
  position: relative;

  .partner-obituary-print-header {
    padding-top: 20px;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }

    img {
      margin: 0 0 20px;

      @include media-breakpoint-up(md) {
        margin: 0 20px 0 0;
      }
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 425px;
    }
  }

  a {
    &.dismiss {
      color: $light-gray-text;
      font-size: 0.875rem;
      position: absolute;
      right: 20px;
      text-transform: uppercase;
      top: 16px;
    }
  }
}

.bg-success-light {
  background-color: rgb(195 230 203) !important;
}

.memorial-unpublish-dialog {
  .modal-footer {
    .btn {
      flex: 1 1 50%;
      min-width: 0;
    }
  }
}

.intl-currency-warning {
  color: $warning-orange;
  margin-top: 6px;
  max-width: $el-input-length;
}

.full-width-add-fundraiser-button {
  background-color: $white;
  border: 1px solid;
  margin: 40px 20px 60px;

  @include media-breakpoint-between(xs, md) {
    width: calc(100% - 40px);
  }

  @include media-breakpoint-up(md) {
    border: 2px dashed;
    margin: 40px 0;
  }
}

.cause-configuration-container {
  .btn {
    &.btn-activate-additional-fundraiser {
      margin: 0 0 20px;
      min-width: 335px;

      @include media-breakpoint-up(md) {
        min-width: 425px;
      }
    }
  }
}

.standalone-story-and-checklist {
  .cause-checklist {
    margin: 0 0 40px;
  }
}

.fundraising-guidance-options {
  .fundraising-guidance-option {
    border: 2px solid $border-color;
    border-radius: $border-radius-lg;
    flex: 0 0 calc(33.33% - 26.67px);
    margin-bottom: 20px;
    padding: 20px;
    transition: border-color 200ms ease-in-out;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    &:hover {
      border-color: $primary-brand-color;
    }

    img {
      margin-right: 40px;
      width: 60px;

      @include media-breakpoint-up(lg) {
        margin-right: 0;
        width: 40%;
      }
    }

    h3,
    p {
      margin-bottom: 6px;
      text-align: left;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        text-align: center;
      }
    }

    h3 {
      color: $black;
    }

    p {
      color: $dark-gray-text;
    }
  }
}

.change-fundraising-manager-container {
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    max-width: 425px;
  }
}

.goal-amount-container {
  .dark-gray-label {
    img {
      height: 1rem;
      margin-top: -3px;
      width: 1rem;
    }
  }
}

.event-covid-precautions {
  .el-checkbox {
    margin-bottom: 8px;
  }
}

.program-attachment-remove-field {
  margin-top: 10px;

  .download-program-link {
    display: block;
    margin-bottom: 10px;
  }

  a {
    img {
      height: 20px;
      margin-right: 8px;
      width: 20px;
    }
  }
}

.edit-events-containers {
  .edit-events-container {
    @include el-light-border-bottom;

    margin-bottom: 40px;

    &.body-card {
      padding-top: 0;
    }

    &:first-of-type {
      &:not(.body-card) {
        padding-top: 40px;
      }
    }

    &:last-of-type {
      &:not(.body-card) {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .event-name {
      padding: 40px 0 20px;

      &.empty {
        color: $dark-gray-text;
        font-style: italic;
      }
    }

    .edit-events-header {
      .remove-event {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .el-input-group {
      &.event-type {
        .dark-gray-label {
          margin-bottom: 20px;
        }

        label {
          display: flex;
          margin-bottom: 0;
        }
      }

      &.half-margin-bottom {
        margin-bottom: 20px;
      }
    }

    .el-radio-btn {
      margin-bottom: 10px;

      label {
        align-items: center;

        div {
          &:last-of-type {
            margin-left: 20px;
          }
        }
      }
    }

    .event-streaming-url-container {
      .borderless-input {
        margin-bottom: 10px;
      }

      .text-note-small {
        max-width: 335px;

        @include media-breakpoint-up(md) {
          max-width: 572px;
        }
      }
    }

    .event-zoom-data-container {
      .zoom-option-container {
        margin-bottom: 20px;
      }
    }

    .checkbox-additional-info {
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }
    }

    .program-attachment-container {
      > div {
        flex: 0 0 calc(50% - 10px);
      }

      .printed-programs-promo {
        align-self: flex-start;
        border: 1px solid;
        border-radius: $border-radius-lg;
        margin-bottom: 40px;
        padding: 20px;

        h3 {
          font-size: 1rem;
          margin-bottom: 6px;
        }

        p {
          font-size: 0.9375rem;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.edit-memorial-content {
  margin: 0 auto;
  max-width: 1095px;

  .alert {
    border-radius: 0;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-sm;
      margin-top: 20px;
    }
  }

  .borderless-input {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: $el-input-length;
    }
  }

  .form-submit-row {
    align-items: flex-start;

    .btn-form-submit {
      @include media-breakpoint-between(xs, md) {
        padding: 0 20px 20px;
      }
    }

    a {
      align-self: flex-start;
      margin-top: 40px;
      padding-left: 20px;

      @include media-breakpoint-up(md) {
        padding-left: 0;
      }

      @include media-breakpoint-up(lg) {
        align-self: stretch;
      }

      &.no-margin {
        margin-top: 0;
      }
    }

    .disclaimer {
      padding: 0 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 0;
        width: 335px;
      }

      a {
        padding: 0;
      }
    }
  }

  .edit-obituary {
    .text-note {
      text-transform: none;
    }
  }

  .profile-upload-container {
    height: 359.53px;
    margin-bottom: 0;
  }
}

.obituary-disclaimer {
  margin-top: 20px;
}

.memorial-update-container {
  .memorial-update-from-contents {
    margin-top: 20px;
    text-align: end;
  }

  .remove-memorial-update {
    margin-bottom: 10px;
  }
}

.page-header {
  &.funeral-home-select-header {
    @include media-breakpoint-between(xs, md) {
      @include light-gray-border-top;

      margin-top: 20px;
      padding-top: 40px;
    }
  }
}

.business-association-review-section {
  @include light-gray-border-top;

  padding-top: 40px;

  .review-form-label {
    margin-bottom: 20px;
  }

  .collapsed-submit-wrapper {
    .btn {
      margin: 0 0 40px;
      max-width: 100%;
      width: 335px;
    }
  }

  .errorlist {
    padding: 0 0 20px;
  }

  .review-success-message {
    padding-bottom: 40px;
  }
}

.memorial-community-questions {
  margin: 0 auto;
  max-width: 1095px;

  &.body-card {
    @include el-light-border-top;

    margin-top: 40px;
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      margin: 20px 0;
      padding: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin: 40px 0;
    }
  }

  .search-city-wrapper {
    padding-bottom: 0;

    .dark-gray-label {
      margin-top: 20px;
    }
  }

  .carousel {
    min-height: 260px;

    @include media-breakpoint-up(md) {
      min-height: 210px;
    }

    @include media-breakpoint-up(lg) {
      min-height: auto;
    }

    .carousel-item {
      .show {
        display: block;
      }

      .question-container {
        margin-bottom: 20px;

        .fw-semibold {
          margin-left: 15px;
        }

        img {
          @include media-breakpoint-between(xs, md) {
            align-self: flex-start;
            margin-top: 8px;
          }
        }
      }
    }

    .carousel-controls {
      margin-top: 20px;
      width: 100%;

      .carousel-indicators {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        position: relative;
      }

      .next-btn,
      .previous-btn {
        div {
          @include media-breakpoint-between(xs, md) {
            max-width: 75px;
            text-align: center;
          }
        }
      }

      .next-btn {
        img {
          margin-left: 10px;

          @include media-breakpoint-up(md) {
            margin-left: 5px;
            margin-top: 5px;
          }
        }
      }

      .previous-btn {
        img {
          margin-right: 10px;

          @include media-breakpoint-up(md) {
            margin-right: 5px;
            margin-top: 2px;
          }
        }
      }
    }

    .carousel-indicators {
      button {
        background-color: $black;
      }
    }

    textarea {
      height: 78px;
    }

    .btn-form-submit {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }

      button {
        @include media-breakpoint-between(xs, md) {
          width: 100%;
        }
      }
    }
  }
}

.body-card-no-padding {
  &.event-state-body-card {
    .event-state-wrapper {
      @include light-gray-border-bottom;

      margin-top: 20px;
      padding: 40px 20px !important;

      @include media-breakpoint-up(md) {
        border-bottom: 0;
        border-radius: $border-radius-lg;
        flex-direction: column;
        margin-top: 40px;
        padding: 40px !important;
      }

      .event-delete-warning {
        color: $red;
        margin-top: 30px;
      }
    }

    .events-state-non-public,
    .events-state-no-info {
      padding: 20px;

      @include media-breakpoint-up(md) {
        padding: 40px;
      }

      .events-note {
        margin-top: 20px;
      }

      .private-event-zoom {
        margin-top: 10px;
      }

      .checkbox-additional-info {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
          margin-left: 15px;
        }

        .zoom-option-container {
          margin-bottom: 20px;
        }
      }
    }

    .add-event-container {
      &.add-padding-bottom {
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
          padding-bottom: 40px;
        }
      }
    }
  }
}

.full-width-add-event-button {
  --#{$prefix}btn-bg: #{$white};

  border: 2px dashed;
  margin: 20px;

  @include media-breakpoint-up(md) {
    margin: 40px 0;
  }
}

.full-width-textarea {
  box-sizing: border-box;
  height: 300px;
  transition: height 200ms ease-in-out;
  width: 100%;

  &.medium-editor-element {
    overflow-y: scroll;

    &:focus {
      height: 80vh;

      @include media-breakpoint-up(lg) {
        height: 500px;
      }
    }
  }
}

.thank-you-note-form {
  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  .el-input-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.edit-memorial-family-container {
  h2 {
    margin-bottom: 0;
  }

  > div {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    > .text-note {
      margin-bottom: 20px;
    }
  }

  .list-group-item {
    &.family-member {
      padding-left: 14px;

      .edit-related-photo-link {
        align-items: center;
        display: flex;

        img {
          height: 13px;
          margin-left: 10px;
          margin-right: 2px;
          width: 15px;
        }
      }

      .list-item-content {
        display: flex;
      }
    }
  }
}

.family-members-container {
  > div {
    flex: auto;

    @include media-breakpoint-up(lg) {
      flex: 0 0 calc(50% - 20px);
    }

    &:first-child {
      margin-bottom: 20px;
    }

    .list-group-item {
      .text-note {
        display: block;
        font-size: 0.8375rem;

        @include media-breakpoint-up(md) {
          display: inline;
          font-size: 0.875rem;
        }

        .comma {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline;
          }
        }
      }
    }
  }

  .add-family-member-button {
    display: block;
    text-align: center;
    width: 100%;

    &.d-none {
      display: none;
    }

    img {
      margin-bottom: 2px;
      margin-right: 4px;
    }
  }
}

.mutate-outbound-relationship-link {
  align-items: center;
  display: flex;
  font-size: 0.9375rem;
  margin-right: 0;
  min-width: auto;
  padding: 0;
  width: auto;

  img {
    margin-right: 3px;
    position: relative;
    top: 1px;
  }
}

.person-edit-form {
  .btn {
    flex: 0 1 calc(50% - 6px);
    min-width: 0;
  }

  .name-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .el-input-group {
      flex-basis: calc(50% - 6px);
      margin: 0;
    }
  }

  .relationship-fields {
    margin-bottom: 20px;

    .custom-select-with-caret {
      margin-bottom: 10px;
    }
  }

  button {
    align-self: flex-end;
  }

  .borderless-input {
    min-width: 0;
    width: 100%;
  }
}

.favorites-container {
  .body-card {
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .favorite {
    border-bottom: 1px solid $medium-gray;
    margin-bottom: 40px;
    padding-bottom: 40px;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.premium-body-card {
  .btn-link {
    min-width: 0;
    padding: 0;
    width: auto;
  }

  .exp-cvc-container {
    > div {
      flex-basis: calc(33.33% - 6.667px);

      @include media-breakpoint-up(md) {
        flex-basis: auto;
        width: 148px;
      }
    }
  }

  ul {
    padding-left: 20px;
  }

  button {
    margin: 0 0 4px;
    min-width: 300px;
    position: relative;
  }

  .premium-fine-print {
    font-size: 0.9375rem;
    max-width: 472px;
  }
}

.premium-upsell-modal {
  .modal-body {
    font-size: 0.9375rem;
    padding: 20px;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }

    .features {
      margin: 20px 0 30px;
    }

    .btn {
      flex-basis: calc(50% - 8px);
      min-width: 0;
    }
  }
}

.premium-preference-toggle {
  margin-top: 20px;
  padding: 20px !important;

  &.disabled {
    label {
      color: $gray-600;

      .link-style-text {
        margin-left: 6px;

        img {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  &.inline {
    background-color: transparent;
    margin: 0 0 10px;
    padding: 0 !important;
  }

  label {
    margin-left: 10px;
  }
}

.premium-subscription-form {
  .btn {
    margin-bottom: 8px;

    @include media-breakpoint-between(xs, md) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

.single-preference-toggle {
  .premium-preference-toggle {
    &.inline {
      margin: 0;
    }

    label {
      margin-bottom: 0;
    }
  }
}

.memorial-gifts-display-preferences-container {
  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.body-card-memorial-gifts {
  .gift-toggles {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    width: 100%;

    &.gifts-hidden {
      background-color: $white;
      border-radius: $border-radius-lg;
    }
  }

  .disable-memorial-gifts-container {
    .premium-preference-toggle {
      margin-bottom: 0;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.body-card-memorial-flowers {
  .premium-preference-toggle {
    margin-bottom: 0;

    label {
      margin-bottom: 0;
    }
  }

  .delivery-addresses-formset-container {
    margin-top: 40px;

    .delivery-address-form {
      @include el-border;

      border-radius: $border-radius-lg;
      margin-bottom: 20px;
      padding: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .remove-address {
        cursor: pointer;
        margin-top: 10px;

        input {
          &[type="checkbox"] {
            display: none;
          }
        }

        img {
          margin-right: 6px;
          margin-top: -3px;
        }
      }

      input {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  .add-address-container {
    margin-top: 40px;
  }
}

.cause-form-inline-icon {
  display: inline-block;
  margin: 4px 6px 0 0;
}

.user-blocking-container {
  > div {
    flex-basis: 100%;

    &:first-child {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      flex-basis: calc(50% - 20px);
    }
  }

  .empty {
    @include el-border;

    background-color: $very-light-gray;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
    color: $gray-500;
    font-size: 1.125rem;
    padding: 20px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 400px;
      padding: 150px 80px 0;
    }
  }

  .list-group {
    background-color: $very-light-gray;
  }

  .user-scroll-container {
    @include el-border;

    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;

    @include media-breakpoint-up(md) {
      height: 400px;
      overflow-y: scroll;
    }
  }

  .search-field-container {
    background-color: $light-gray;
    padding: 8px;

    .search-field {
      background-color: $white;
      border: 1px solid $medium-gray;
      border-radius: 2px;
      min-width: auto;
      padding: 4px;
      width: 100%;
    }
  }

  .blockable-user {
    border-left: 0;
    border-right: 0;
    display: flex;
    justify-content: space-between;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-radius: 0;
    }

    .profile-link {
      font-size: 0.875rem;
    }

    > :last-child {
      align-self: center;
      flex-shrink: 0;
    }
  }
}

.donated-organs-details-container {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.need-form-container {
  border-bottom: 1px solid $border-color;
  padding: 20px;

  @include media-breakpoint-up(md) {
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    margin-bottom: 20px;
  }

  .need-title {
    .el-checkbox {
      .pseudo-checkbox {
        height: 30px;
        width: 30px;
      }

      input {
        &[type="checkbox"] {
          height: 30px;
          width: 30px;

          &:checked {
            ~ .pseudo-checkbox {
              background-size: 24px auto;
            }
          }
        }
      }
    }

    .need-title-label {
      font-size: 1.3125rem;
      margin: 0 0 0 10px;
    }

    .small-button {
      flex-shrink: 0;
    }

    .el-animated-placeholder-label-input {
      &.filled {
        label {
          top: -50px;
        }
      }

      input {
        margin-bottom: 0;
      }

      label {
        top: -36px;
      }
    }
  }

  .need-details {
    margin: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: all 700ms ease-in-out;

    &.show {
      &:not(.no-details) {
        border-top: 1px solid $border-color;
        margin-top: 16px;
        max-height: none;
        padding-top: 20px;

        @include media-breakpoint-up(md) {
          max-height: 1000px;
        }

        &.has-volunteers {
          max-height: none;
        }

        &.onboarding {
          > div {
            flex-basis: 100%;

            &:first-child,
            &:nth-child(2) {
              border-right: 0;
              margin-right: 0;
              padding-right: 0;
            }
          }
        }
      }
    }

    > div {
      flex: 0 0 50%;

      &:first-child,
      &:nth-child(2) {
        @include media-breakpoint-up(md) {
          border-right: 1px solid $gray-300;
          margin-right: 20px;
          padding-right: 20px;
        }
      }
    }

    .need-details-readonly {
      @include media-breakpoint-up(md) {
        .expandable-collapse {
          &.show {
            &:not(.short) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .el-input-group {
      margin-bottom: 10px;
    }

    .empty-label {
      color: $lighter-gray-text;
      font-size: 1rem;
      margin-bottom: 30px;
      margin-top: 30px;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: 1.3125rem;
      }
    }

    .need-details-header-container {
      margin-bottom: 20px;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .need-volunteers {
    height: 100%;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .list-group {
      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }

      .list-group-item {
        align-items: flex-start;
        flex-direction: column;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          align-items: center;
          flex-direction: row;

          > :first-child {
            margin-bottom: 10px;
          }

          .btn {
            width: 100%;
          }
        }
      }
    }

    .need-volunteer {
      margin: 0 10px 0 0;

      img {
        border: 2px solid;
        margin: 0;
      }
    }
  }
}

.add-other-need {
  --#{$prefix}btn-bg: #{$white};

  border: 2px dashed;
  margin: 20px 0 0;

  @include media-breakpoint-up(md) {
    margin: 40px 0;
  }
}

.charity-cause-name {
  font-size: 1.3125rem;
  margin-bottom: 20px;

  .choose-new-cause-button {
    @include media-breakpoint-up(md) {
      margin-left: 16px;
    }
  }
}

.cause-name-input-group {
  margin-bottom: 20px;
}

.cause-name-container {
  margin-bottom: 8px;
}

.invite-person-tip {
  background-color: $light-blue;
  border: 1px solid $medium-blue;
  border-radius: $border-radius-lg;
  color: $dark-blue;
  flex: 0 0 100%;
  font-size: 0.9375rem;
  padding: 15px 30px;

  @include media-breakpoint-up(lg) {
    flex: 0 0 calc(50% - 20px);
  }
}

.cause-description {
  font-size: 1.1rem;
  margin: 30px;

  @include media-breakpoint-between(xs, md) {
    text-align: center;
  }
}

.choose-cause-container {
  .cause-name {
    font-size: 1.3rem;
    font-weight: $font-weight-semibold;
    margin: 50px 10px 5px;
    text-align: center;
  }

  .form-submit-row {
    justify-content: flex-end;
    margin-right: 40px;
  }

  .charity-search-container {
    @include media-breakpoint-up(md) {
      margin: 20px 20px 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    .charity-search-label {
      flex: auto;
      font-size: 1.375rem;
      font-weight: $font-weight-semibold;
      margin: 0 0 10px;
      text-align: center;

      @include media-breakpoint-up(md) {
        flex: 0 0 auto;
        font-size: 1.25rem;
        margin: 0 0 8px;
        text-align: start;
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 240px;
        margin: 0;
      }
    }
  }

  .charity-search-btn-form-submit {
    .btn {
      &.btn-submit-typed-charity {
        margin-top: 20px;
      }
    }
  }

  .cause-separator-container {
    color: $light-gray-text;
    margin: 30px 0;

    .divider-line {
      min-width: 0;

      @include media-breakpoint-up(md) {
        border-bottom: 1px solid;
        margin: 0 4px 9px;
        min-width: 18%;
      }

      @include media-breakpoint-up(lg) {
        min-width: 28%;
      }
    }

    .separator-text {
      font-size: 1.1rem;
      text-align: center;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 0.9rem;
        text-align: start;
      }
    }
  }

  .cause-cards-container {
    margin-bottom: 20px;

    .cause-cards {
      @include media-breakpoint-up(md) {
        padding: 15px 0;
      }
    }
  }
}

.suggested-charity-container {
  margin-bottom: 40px;
  margin-top: 30px;

  .suggested-charity-contents {
    border-radius: $border-radius-lg;
    padding: 20px;
    width: 100%;

    @include media-breakpoint-between(xs, md) {
      margin: 0;
    }

    .cause-name {
      margin-top: 20px;
      text-align: center;
    }

    .suggested-charity-title {
      border-bottom: 1px solid rgb(128 128 128);
      padding-bottom: 25px;
      text-align: center;
      width: 95%;
    }
  }
}

.cause-card {
  flex-basis: 100%;
  margin: 10px 0;
  position: relative;

  @include media-breakpoint-up(md) {
    flex-basis: calc((100% / 3) - 13.33px);
  }

  @include media-breakpoint-up(xl) {
    flex-basis: calc((100% / 4) - 15px);
  }

  .rectangle {
    background-color: var(--secondary-brand-color-light);
    height: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 25px;
      width: 100%;
    }
  }

  .close-cause {
    cursor: pointer;
    margin-right: 4px;
  }

  .cause-card-inner {
    border-radius: $border-radius-lg;
    cursor: pointer;
    height: 180px;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 190px;
      width: 100%;
    }

    .cause-title {
      color: $black;
      font-size: 1.25rem;
      margin-top: 10px;
    }

    &:hover {
      background-color: $blue-gray-background;
    }
  }

  .cause-card-expander {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc((100% * 3) + 40px);
    z-index: $zindex-default;

    @include media-breakpoint-up(xl) {
      width: calc((100% * 4) + 60px);
    }

    .btn-form-submit {
      margin-bottom: 20px;

      @include media-breakpoint-between(xs, md) {
        justify-content: center;
        margin-right: 0;
      }

      .btn-lg {
        @include media-breakpoint-between(xs, md) {
          width: 80%;
        }
      }
    }
  }

  &.is-collapsed {
    .close-cause {
      opacity: 0;
    }

    .cause-card-expander {
      max-height: 0;
      min-height: 0;
      opacity: 0;
      overflow: hidden;
    }

    .cause-card-inner {
      &::after {
        opacity: 0;
      }
    }
  }

  &.is-expanded {
    .close-cause {
      opacity: 1;
    }

    .cause-card-inner {
      background-color: $blue-gray-background;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        bottom: -30px;
        display: block;
        height: 0;
        left: calc(50% - 15px);
        opacity: 1;
        position: absolute;
        width: 0;
      }
    }

    .cause-card-expander {
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      min-height: 200px;
      opacity: 1;
      overflow: visible;
    }
  }

  // every expander/detail section (mobile)
  &:nth-of-type(n) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-between(xs, md) {
          border-radius: 0 0 $border-radius-lg $border-radius-lg;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  // 2nd in row of 3 (tablet/alison)
  &:nth-of-type(3n + 2) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-between(md, xl) {
          border-top-right-radius: $border-radius-lg;
          margin-left: calc((-100%) - 20px);
        }
      }
    }
  }

  // 3rd in row of 3 (tablet/alison)
  &:nth-of-type(3n + 3) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-between(md, xl) {
          border-top-left-radius: $border-radius-lg;
          border-top-right-radius: 0;
          margin-left: calc((-100% * 2) - 40px);
        }
      }
    }
  }

  // 2nd in row of 4 (desktop)
  &:nth-of-type(4n + 2) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-up(xl) {
          border-radius: $border-radius-lg;
          margin-left: calc((-100%) - 20px);
        }
      }
    }
  }

  // 3rd in row of 4 (desktop)
  &:nth-of-type(4n + 3) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-up(xl) {
          border-top-left-radius: $border-radius-lg;
          border-top-right-radius: $border-radius-lg;
          margin-left: calc((-100% * 2) - 40px);
        }
      }
    }
  }

  // 4th in row of 4 (desktop)
  &:nth-of-type(4n + 4) {
    &.is-expanded {
      .cause-card-expander {
        @include media-breakpoint-up(xl) {
          border-top-left-radius: $border-radius-lg;
          border-top-right-radius: 0;
          margin-left: calc((-100% * 3) - 61px);
        }
      }
    }
  }
}

.memorial-theme-chooser {
  padding: 40px 30px !important;
}

.memorial-theme-option {
  flex: 0 1 calc(50% - 10px);
  margin: 0 5px 10px;
  position: relative;

  @include media-breakpoint-up(lg) {
    flex: 0 1 calc(20% - 11px);
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
    width: 100%;
  }

  input {
    &[type="radio"] {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;

      &:checked {
        ~ .cp-container {
          border-color: $secondary-brand-color;
        }
      }
    }
  }

  + label {
    cursor: pointer;
  }
}

.invite-people-formset-container {
  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  .invite-person-survivor-form {
    @include el-border;

    border-radius: $border-radius-lg;
    flex: 0 0 100%;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;

    @include media-breakpoint-up(xl) {
      flex: 1 0 auto;
      max-width: calc(50% - 10px);
    }

    .remove-invite-person-form {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .borderless-input {
    flex: 0 0 100%;
    width: 100%;
  }

  .name-entry {
    .borderless-input {
      flex: 0 0 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex: 0 0 calc(50% - map.get($spacers, 1));
        width: auto;
      }

      @include media-breakpoint-up(xl) {
        flex: 1 0 auto;
        max-width: calc(50% - map.get($spacers, 1));
      }

      &:first-child {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  // TODO: Refactor small-button
  .btn-group {
    .small-button {
      margin: 0;
    }
  }

  .remove-invite-person-form {
    background-color: $white;
    border-radius: 50%;
    color: $gray-600;
    display: block;
    font-size: 1.5rem;
    height: 24px;
    width: 24px;
  }
}

.add-invite-person-btn {
  align-self: flex-start;
  border: 2px dashed;
  border-radius: $border-radius-lg;
  flex: 0 0 100%;
  margin: 0 0 20px;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(50% - 10px);
    margin: 0;
  }
}

.program-text-cta {
  margin: 10px 0 40px;
}

.onboarding-goals-container {
  > .d-flex {
    &:not(:first-of-type) {
      margin-top: 20px;
    }

    label {
      margin-left: 10px;
    }

    .wants-other-container {
      input {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}

.site-published-card {
  @include media-breakpoint-between(xs, md) {
    background-color: $gray-100 !important;
    border-bottom: 1px solid $border-color;
  }

  h2 {
    margin: 0;

    @include media-breakpoint-between(xs, md) {
      font-size: 1rem;
    }
  }

  img {
    margin-right: 20px;

    @include media-breakpoint-between(xs, md) {
      height: 60px;
      width: 60px;
    }
  }

  p {
    margin: 0;

    @include media-breakpoint-between(xs, md) {
      font-size: 0.875rem;
    }
  }
}

.charity-search-helper-modal {
  text-align: center;

  .btn-container {
    width: 100%;
  }

  .btn {
    min-width: 140px;

    @include media-breakpoint-up(md) {
      min-width: 215px;
    }
  }
}

.edit-cause-container {
  justify-content: space-between;
  padding-bottom: 40px;

  .cause-inputs {
    width: 100%;

    @include media-breakpoint-between(xs, lg) {
      margin-right: 0;
    }

    .charity-location-and-ein-note {
      margin-bottom: 0.25rem;
    }

    .cause-url-container {
      margin-top: 40px;
    }

    .goal-container {
      @include el-border-bottom;

      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 472px;
      }

      span {
        @extend %base-h3;

        margin: 0 4px 2px 0;
      }

      .borderless-input {
        border: 0;
        height: 100%;
        width: 100%;
      }
    }

    .goal-no-goal {
      display: flex;
      margin-top: 10px;
    }

    .el-input-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .donor-choice-charities-tip {
    @include media-breakpoint-between(xs, lg) {
      margin: 0;
    }

    .tip-body {
      margin-left: 0;
      padding: 20px 40px;
    }
  }

  .story-and-checklist {
    .el-input-group {
      flex: 1 0 50%;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .cause-checklist {
    flex: 0 1 50%;
    margin: 0 0 40px;

    @include media-breakpoint-up(md) {
      margin: 30px 0 0 20px;
    }

    @include media-breakpoint-up(lg) {
      width: 466px;
    }

    .tip-header {
      padding: 17px 30px;
    }

    .tip-body {
      padding: 0;
    }

    .nav-arrow-list {
      flex-direction: column;
      padding: 20px;

      @include media-breakpoint-only(md) {
        flex-direction: row;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px 40px;
      }

      .nav-arrow {
        margin-bottom: 0;
        padding-left: 30px;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 50%;
        }

        @include media-breakpoint-up(lg) {
          width: auto;
        }

        &:first-of-type {
          margin-right: 20px;

          @include media-breakpoint-up(lg) {
            margin-right: 0;
          }
        }

        li {
          padding: 0 0 10px 10px;
        }
      }
    }
  }

  .currency-no-goal-container {
    margin-top: 10px;

    .custom-select-with-caret {
      width: 86px;
    }
  }
}

.edit-managers-container {
  .add-manager-container {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .sharing-btn {
      display: flex;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 420px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 472px;
      }

      .copy-cta-text {
        flex-shrink: 0;
      }

      .sharing-url {
        flex-shrink: 1;
      }
    }
  }

  .manager-identity {
    a {
      margin-right: 10px;
    }

    .badge {
      position: relative;
      top: -1px;
    }
  }

  .remove-manager-link {
    align-items: center;
    display: flex;
    font-size: 0.9375rem;

    img {
      margin-right: 3px;
      position: relative;
      top: 1px;
    }
  }

  > div {
    @include media-breakpoint-up(md) {
      flex-basis: calc(50% - 20px);
    }

    &:first-child {
      @include media-breakpoint-between(xs, md) {
        margin-bottom: 20px;
      }
    }
  }
}

.sidebar {
  &.manager-public-sidebar {
    padding-top: 20px;
    top: 140px;
  }
}

.stock-photo-importer {
  .selected-photos {
    > div {
      border: 1px solid $white;
      width: 25% !important;

      @include media-breakpoint-up(md) {
        width: 12.5% !important;
      }
    }
  }
}

.stock-photo-browser {
  &.full-selection {
    .stock-photo {
      cursor: not-allowed;
    }
  }

  .d-grid {
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }

    .stock-photo {
      transition: all 100ms ease-in-out;

      .title {
        transition: all 100ms ease-in-out;
      }

      &:hover,
      &.selected {
        .title {
          background-color: var(--primary-brand-color);
          color: $white;
        }
      }
    }
  }
}

.btn-mobile-fullwidth {
  @include media-breakpoint-between(xs, md) {
    max-width: 100%;
    width: 100%;
  }
}

.unsaved-changes-indicator {
  color: $orange;
  font-size: 0.9375rem;
  padding: 6px 0;

  a {
    color: $orange;
    font-weight: $font-weight-semibold;

    &:hover {
      color: $orange;
      font-weight: $font-weight-semibold;
    }
  }

  img {
    height: 0.8rem;
    margin-bottom: 1px;
    margin-right: 2px;
    width: 0.8rem;
  }
}

.cp-container {
  border: 2px solid $border-color;
  border-radius: 6px;
  height: 146px;
  overflow-y: hidden;
  padding: 4px;
  width: 100%;

  @include media-breakpoint-only(md) {
    height: 226px;
  }

  .cp-navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .cp-navbar-logo {
      background-color: $primary-brand-color;
      border-radius: 2px;
      height: 4px;
      width: 20%;
    }

    .cp-navbar-menu {
      display: flex;
      justify-content: flex-end;
      width: 80%;

      .cp-navbar-menu-item {
        background-color: $primary-brand-color;
        border-radius: 2px;
        height: 4px;
        margin-left: 2px;

        &.lg {
          width: 10%;
        }

        &.sm {
          width: 5%;
        }
      }
    }
  }

  .cp-header {
    height: 60%;
    width: 100%;

    .cp-header-name {
      background-color: $border-color;
      border-radius: 2px;
      height: 10px;
      margin: 6px 0 4px;
      width: 70%;
    }

    .cp-header-photos {
      display: flex;
      height: calc(100% - 20px);
      width: 100%;

      .cp-header-photos-main {
        background-color: $border-color;
        border-radius: 2px;
        flex: 0 0 50%;
        height: calc(100% + 2px);
        margin-top: 1px;
      }

      .cp-header-photos-secondary {
        display: flex;
        flex: 0 0 50%;
        flex-wrap: wrap;
        margin-left: 1px;

        .cp-header-photos-secondary-photo {
          background-color: $border-color;
          border-radius: 2px;
          height: 50%;
          margin: 1px;
          width: calc(50% - 2px);
        }
      }
    }
  }

  .cp-memorial-nav {
    display: flex;
    margin: 4px 0;

    .cp-memorial-nav-item {
      background-color: $primary-brand-color;
      border-radius: 2px;
      height: 4px;
      margin-right: 3px;
      width: 16px;
    }
  }

  .cp-memorial-content {
    display: flex;
    width: 100%;

    .cp-memorial-content-main {
      flex: 1 1 auto;

      .cp-memorial-content-title {
        background-color: $border-color;
        border-radius: 2px;
        height: 10px;
        width: 70%;
      }

      .cp-memorial-content-button {
        background-color: $secondary-brand-color;
        border-radius: 2px;
        height: 10px;
        margin-top: 4px;
        width: 45%;
      }
    }

    .cp-memorial-content-sidebar {
      background-color: $light-teal;
      border-top: 2px solid $secondary-brand-color;
      flex: 0 0 33%;
      height: 60px;
    }
  }
}
