// Private invites page

.borderless-input {
  &.el-invite-input {
    max-width: 335px;

    @include media-breakpoint-up(md) {
      max-width: none;
      width: 100%;
    }
  }

  &.el-intro-input {
    max-width: 335px;
    width: 335px;
  }
}

.edit-template {
  @include media-breakpoint-up(md) {
    background-image: url("../img/browser-chrome.png");
    background-position-y: 40px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .el-input-group {
    &:first-of-type {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 80px;
      }
    }
  }
}

.invite-icon-row {
  padding-bottom: 25px;
}

.invite-image {
  border-radius: $border-radius-lg;
  margin-bottom: 40px;
  max-height: 100%;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-height: 280px;
    max-width: 371px;
  }
}

textarea {
  &.invite-note {
    @include el-border;

    border-radius: $border-radius-lg;
    height: 140px;
    padding: 20px;
    resize: none;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 800px;
    }
  }
}

.invite-event {
  margin-bottom: 44px;
}

.invite-event-title {
  font-size: 21px;
  font-weight: $font-weight-semibold;
  margin-bottom: 10px;
}

.invite-form {
  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }
}

.import-contacts-cta-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .open-import-contacts-button {
    margin: 0 0 8px !important;
    padding: 10px !important;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 0.375rem 14px !important;
      width: auto;
    }
  }
}

.import-google-contacts-modal {
  .modal-body {
    padding: 0;
    position: relative;

    .contact-search-container {
      border-bottom: 1px solid $border-color;
      padding: 8px;
      position: relative;

      .contact-search {
        appearance: none;
        background-color: $light-gray;
        border: 0;
        border-radius: $border-radius-sm;
        box-shadow: none;
        padding: 8px;
        width: 100%;
      }

      .clear-contact-search {
        height: 16px;
        position: absolute;
        right: 16px;
        top: calc(50% - 14px);
        width: 16px;

        img {
          height: 16px;
          width: 16px;
        }
      }
    }

    .contact-list-group {
      max-height: 60vh;
      min-height: 120px;
      -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
      overflow-y: scroll;
      scroll-behavior: smooth;

      @include media-breakpoint-up(md) {
        max-height: 40vh;
      }

      .contact-list-group-item {
        align-items: center;
        display: flex;
        flex-shrink: 0 !important;

        &.active {
          background-color: $light-blue;
          border-color: $medium-blue;

          &:last-child {
            border-bottom-width: 1px;
          }

          .checkbox {
            background-image: url("../svg/checkmark-circle-outline-medium-blue.svg");
          }

          .contact {
            h5,
            span {
              color: $dark-gray-text;
            }
          }
        }

        .checkbox {
          background-image: url("../svg/checkmark-circle-outline-secondary-incomplete-24.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          flex-shrink: 0;
          height: 24px;
          width: 24px;
        }

        .contact {
          align-items: baseline;
          display: flex;
          flex-direction: column;
          flex-shrink: 1;
          margin-left: 10px;
          min-width: 0;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-left: 6px;
          }

          h5 {
            flex-shrink: 0;
            font-weight: $font-weight-semibold;
            margin: 0 6px 0 0;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            color: $light-gray-text;
            font-weight: $font-weight-light;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .modal-body-overlay {
      align-items: center;
      background-color: $white-overlay;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      min-height: 120px;
      padding-left: 20px;
      padding-right: 20px;
      position: absolute;
      top: 0;
      width: 100%;

      &.opaque {
        background-color: $white;
      }
    }
  }
}

.btn {
  &.btn-google-auth {
    background-color: $google-blue;
    color: $white;
    max-width: none;
    padding-left: 20px;
    padding-right: 20px;

    &:active,
    &:hover {
      background-color: $google-blue;
      color: $white;
    }

    img {
      height: 24px;
      margin-right: 6px;
      width: 24px;
    }
  }
}

.invite-records-body-card {
  height: 100px;
  overflow-y: hidden;
  transition: all 200ms ease-in-out;

  @include media-breakpoint-up(md) {
    height: 120px;
  }

  > div {
    &:first-child {
      margin-bottom: 40px;
    }
  }

  h2 {
    margin: 0 14px 0 0;
  }

  &.collapsed {
    .toggle-collapse-invite-records {
      .hide {
        display: none;
      }

      .show {
        display: inline;
      }
    }
  }

  .toggle-collapse-invite-records {
    .hide {
      display: inline;
    }

    .show {
      display: none;
    }
  }

  .table {
    font-size: 0.9375rem;
    margin: 0;
    width: 100%;

    .checkmark {
      text-align: center;
    }

    td,
    th {
      font-size: 0.875rem;
      padding: 2px;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        padding: 0.75rem;
      }
    }
  }
}
